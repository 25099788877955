<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './CitaViewData'
import { CITA } from '@/utils/consts'


export default {
  components: {},
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    tipo: {
      type: String,
      default: '',
    },
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'fcita', label: 'Fecha de inicio', filter: this.$options.filters.shortDateTime },
        { name: 'ffin', label: 'Fecha de fin', filter: this.$options.filters.shortDateTime },
      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        realizada: {
          title: 'Marcar como realizada',
          visible: true,
          icon: 'check'
        },
        pendiente: {
          title: 'Volver a pendiente',
          visible: true,
          icon: 'check'
        },
        anular: {
          title: 'Anular cita',
          visible: true,
          icon: 'delete'
        },
        delete: {
          title: 'Eliminar cita',
          visible: true,
          icon: 'delete'
        },
      },
      CITA,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcita
        item.title = this.$options.filters.linebreaksBr(item.titulo)
        item.subtitle = this.$options.filters.linebreaksBr(item.cita)
        item.badge = CITA.descripcionesEstado[item.estado]
        item.badgeColor = CITA.coloresEstado[item.estado]
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.titulo
      const hasEditPerm = this.hasEditPerm(this.$route.meta.permission.idobjeto)
      this.toolbarOptions.realizada.visible = hasEditPerm && this.item.dataset.estado === CITA.estado.pendiente
      // anular no es borrar
      this.toolbarOptions.anular.visible = hasEditPerm && this.item.dataset.estado === CITA.estado.pendiente
      this.toolbarOptions.delete.visible = this.hasDeletePerm(this.$route.meta.permission.idobjeto)
      this.toolbarOptions.pendiente.visible = hasEditPerm &&
        (this.item.dataset.estado === CITA.estado.anulado || this.item.dataset.estado === CITA.estado.realizado)
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectCita(
          this, this.routeParams.idcita
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // operaciones
      if (this.hasViewPerm(this.permissions.operaciones.id) && this.item.dataset.identificador !== null) {
        this.addDetail(
          'operacionCRM', 'Operación', 'Operacion relacionada con la cita', 'operacionCRM'
        )
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'operacionCRM') {
        this.$appRouter.push({
          name: 'operacionescrm__operacioncrm-view',
          params: {
            idoperacioncrm: this.item.dataset.identificador
          },
        })
      } else if (data.detail.name === 'agenda') {
        this.$appRouter.push({
          name: 'comercial__comercial-agenda',
          query: {
            fecha: this.item.dataset.fcita,
          },
        })
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idcita: this.routeParams.idcita,
        },
      })
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.realizada) {
        this.setRealizada()
      } else if (option === this.toolbarOptions.anular){
        this.setAnulada()
      } else if (option === this.toolbarOptions.pendiente){
        this.setPendiente()
      } else if (option === this.toolbarOptions.delete){
        this.delete()
      }
    },
    async setRealizada () {
      const res = await this.$alert.showConfirm(`¿Deseas marcar la cita "${this.item.dataset.titulo}" como realizada`)
      if (res) {
        await Data.setCitaRealizada(
          this,
          this.routeParams.idcita
        )
        this.$alert.showSnackbarSuccess(`Se ha marcado la cita "${this.item.dataset.titulo}" como realizada`)
        this.loadPage()
      }
    },
    async setAnulada () {
      const res = await this.$alert.showConfirm(`¿Deseas anular la cita "${this.item.dataset.titulo}"`)
      if (res) {
        await Data.setCitaAnulada(
          this,
          this.routeParams.idcita
        )
        this.$alert.showSnackbarSuccess(`Se ha anulado la cita "${this.item.dataset.titulo}"`)
        this.loadPage()
      }
    },
    async setPendiente () {
      const res = await this.$alert.showConfirm(`¿Deseas pasar a pendiente la cita "${this.item.dataset.titulo}"`)
      if (res) {
        await Data.setCitaPendiente(
          this,
          this.routeParams.idcita
        )
        this.$alert.showSnackbarSuccess(`Se ha vuelto a pendiente la cita "${this.item.dataset.titulo}"`)
        this.loadPage()
      }
    },
    async delete () {
      const res = await this.$alert.showConfirm(`¿Deseas eliminar permanentemente la cita "${this.item.dataset.titulo}"`)
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await this.$online.cita.delete(this.routeParams.idcita)
          await this.$dirty.deleted(this.$dirty.ENTITIES.remote.cita, this.routeParams.idcita)
        } finally {
          this.$loading.hide()
        }
        this.$appRouter.go(-1)
      }
    },
  }
}
</script>
