import { APIFilter } from '@/utils/api'
import { CITA } from '@/utils/consts'

export default {
  async selectCita (Vue, idcita) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idcita', idcita)
    const resp = await Vue.$api.call('cita.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async cambiarEstadoCita(Vue, id, estado) {
    await Vue.$api.call(
      'cita.update',
      {
        values: {
          idcita: id,
          estado: estado,
        },
      },
    )
  },
  async setCitaRealizada (Vue, id) {
    await this.cambiarEstadoCita(Vue, id, CITA.estado.realizado)
  },
  async setCitaAnulada (Vue, id) {
    await this.cambiarEstadoCita(Vue, id, CITA.estado.anulado)
  },
  async setCitaPendiente (Vue, id) {
    await this.cambiarEstadoCita(Vue, id, CITA.estado.pendiente)
  },
}
